import Advantage from '../advantage';
import {content} from './sectionsData';

export default function AdvantagesSection() {
  {/* Maping the sections to render */}
  return (
    <section>
      {
        content.map((item, index) => (
          <Advantage 
            key={index} 
            type={index % 2 === 0 ? 'white' : 'blue'} 
            item={item} />
        ))
      }
    </section>
  );
}