import { configureStore } from '@reduxjs/toolkit';
import { default as popupReducer } from './reducers/popupReducers';


const reducer = {
  popup: popupReducer,
};

const preloadedState = {
  popup: false,
};

const store = configureStore({ 
  reducer: reducer, 
  devTools: process.env.NODE_ENV !== 'production', 
  preloadedState 
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store;