import styles from './BeAIntegrator.module.scss';
import classnames from 'classnames';
import devicesImage from '../../assets/images/beAIntegrator/Group 133.png';
import manImage from '../../assets/images/beAIntegrator/image 16.png';
import AppButton from '../button';

export default function BeAIntegratorSection() {
  return (
    <div className={styles['sl-c-integrator-section']}>
      <img src={devicesImage} alt="Página da Solar vista em smartphone e em um notebook." className={styles.image} />
      <img src={manImage} alt="Imagem de um homem por trás de um fundo azul." className={styles.man} />
      <div className={styles.content}>
        <div className={styles.wrapper}>
          <p className={styles.title}>Seja um integrador</p>
          <p className={styles.paragraph}><span className={styles.detach}>Seja um integrador Solar Life Energy</span> e tenha acesso ao nosso programa de fidelização, que oferece <span className={styles.detach}>descontos e benefícios</span> mediante o cadastro gratuito do seu CPF ou CNPJ no nosso sistema, com objetivo de mapear as necessidades individuais de cada cliente e integrador. </p>
          <p className={styles.paragraph}>Você coleciona pontos e pode trocá-los por <span className={styles.detach}>vantagens exclusivas</span> e prêmios incríveis, como drones, kits e materiais para o seu negócio!</p>
          <p className={styles.paragraph}>
            Além disso, você será avisado(a) com <span className={styles.detach}>antecedência</span> de planos, promoções, novidades, chegada de containers e muito mais! 
            <br />
            Você ficará sabendo antes mesmo das notícias serem publicadas em nossos canais oficiais.         
          </p>
          <div style={{marginTop: '2rem'}}>
            <a className={styles.transparent} href="http://wa.me/5581982281022" target="_blank" rel="noreferrer"> 
              <AppButton color='orange'>Cadastre-se agora!</AppButton> 
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}