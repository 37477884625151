export interface Testemonial {
  photo?: string;
  name: string;
  legend: string;
  message: string;
}

const content: Testemonial[] = [
  {
    name: 'Gabriel Lucena',
    legend: 'Integrador Maceió - AL',
    message: 'A Solar Life é uma empresa sólida no mercado, trabalha com pronta entrega, tem boas marcas de equipamentos, tudo isso nos passa muita segurança, é uma boa empresa para se ter uma parceria.'
  },
  {
    name: 'Michael Ramos',
    legend: 'Integrador Recife - PE',
    message: 'Como integrador da Solar Life Energy, estou satisfeito com as facilidades e benefícios que a empresa proporciona. A qualidade dos produtos, a agilidade no atendimento e a expertise da equipe são impressionantes. Estou orgulhoso em ser parceiro de uma empresa comprometida como a Solar Life Energy.'
  },
  {
    name: 'Luciano Fonseca',
    legend: 'Integrador João Pessoa - PB',
    message: 'A Solar Life Energy facilita muito a vida dos clientes na hora da compra, tornando os equipamentos fotovoltaicos muito mais acessíveis por meio das suas formas de pagamentos e linhas de financiamento. Me sinto realizado em poder oferecer soluções sustentáveis que se encaixam no orçamento dos meus clientes.'
  },
];

export { content };