import { 
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import {default as P} from './pages';
import * as C from './components';

export class Paths {
  public static home = '/';
  public static aboutUs = '/sobre';
  public static faq = '/perguntas';
  public static products = '/produtos';
  public static highlights = '/destaques';
}

export default function AppRouter() {
  return (
    <Router>
      <C.Navbar />
      <Routes>
        <Route path={Paths.home} element={<P.Home />} />
        <Route path={Paths.aboutUs} element={<P.AboutUs />} />
        <Route path='*' element={<P.NotFound />} />
        {/* <Route path={Paths.faq} element={<P.Faq />} /> */}
        {/* <Route path={Paths.products} element={<P.Products />} /> */}
        {/* <Route path={Paths.highlights} element={<P.Highlights />} /> */}
      </Routes>
      <C.Footer />
    </Router>
  );
}