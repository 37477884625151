import * as C from '../../components';
import { Index as Banner} from '../banner';
import { Index as Products} from '../products';
import { Index as Highlights} from '../highlights';

export const Index = () => {
  return (
    <>
      <Banner/>
      <Products/>
      <Highlights/>
      <C.MeetAdvantage />
      <C.Advantages />
      <C.BeAIntegratorSection />
      <C.Testimonials />
      <C.Contact />
    </>
  );
};