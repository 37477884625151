import { Toast, ToastContainer , } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as PopupActions from '../../store/actions/popupActions';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import styles from './Alert.module.scss';

export default function Alert() {
  const dispatch = useDispatch();

  const show = useSelector((state: RootState) => state.popup);

  const closeAlert = (e: any) => {
    dispatch(PopupActions.hidePopup);
  };

  return (
    <div className={styles['sl-c-alert']}>
      <Toast show={show} onClose={closeAlert}>
        <Toast.Header>
          <img
            src="holder.js/20x20?text=%20"
            className="rounded me-2"
            alt=""
          />
          <strong className="me-auto">Solar Life Energy</strong>
          <small>11 mins ago</small>
        </Toast.Header>

        <Toast.Body>Formulário enviado com sucesso! Em breve, entraremos em contato para que possamos dar um retorno ;)</Toast.Body>
      </Toast>
    </div>
  );
}