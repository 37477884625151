import styles from './AboutUs.module.scss';
import classnames from 'classnames';
import mobileMap from '../../assets/images/aboutUs/Mapa.png';
import map from '../../assets/images/aboutUs/Mapa-1.png';
import pin from '../../assets/icons/pin-red.png';

export const Index = () => {  
  return (
    <section className={
      classnames(
        
        styles['sl-c-about'],
      )}>

      <div className={classnames(styles.content, styles.spacer)}>
        <p className={classnames(styles.title,styles.white, 'mb-5')}>Sobre nós</p>


        <div className={styles.paragrath}>
          <p className={styles.text}>
            A <span className={styles.detach}>Solar Life Energy</span> é uma distribuidora de equipamentos fotovoltaicos com mais de 10 anos de atuação, contando com <span className={styles.detach}>amplo estoque</span> e <span className={styles.detach}>centros de distribuição </span>em localizações estratégicas. Atendemos todo o Nordeste e os estados de São Paulo e Santa Catarina.
          </p>
              
          <p className={styles.text}>
            Com <span className={styles.detach}>excelência e agilidade</span> em nosso atendimento, garantimos um pós-venda presente e humanizado, trabalhamos com uma grande <span className={styles.detach}>equipe de especialistas</span> e com as melhores marcas do setor solar.
          </p>

          <p className={styles.text}>
          Somos amigos do integrador e oferecemos o <span className={styles.detach}>suporte necessário</span> para suas instalações e cliente, sempre buscando a melhor forma de <span className={styles.detach}>atender e facilitar o dia a dia</span> de nossos parceiros, com entrega do material diretamente na obra e <span className={styles.detach}>flexibilizando</span> com diversas <span className={styles.detach}>formas de pagamento</span>.
          </p>

          
        </div>

        <div className={classnames(styles['image-container'])}>
          <div className={classnames(styles.paragrath, styles['image-title'])}>
            <p className={classnames(styles.text, styles.center,)}>
              O futuro com a <span className={styles.detach}>Solar Life Energy</span> é sustentável, econômico, inovador e de grandes conquistas! Estamos juntos sempre no apoio total a você, amigo integrador.
            </p>
            <p className={classnames(styles.title , styles.white, 'mb-5')}>Porque o sol, é a sua energia!</p>
          </div>

          {/* Displaying image according to screen width. */}
          <img src={mobileMap} className={classnames(styles.map, styles['mobile-map'])} />
          <img src={map} className={classnames(styles.map, styles['desktop-map'])} />

          <div className={classnames(styles.acting)}>
            <img src={pin} alt="Ícone" />
            <p>Onde atuamos</p>
          </div>

          <div className={classnames(styles.regions)}>
            <div className={styles.region}>
              <p className={styles['region-title']}>Centro de distribuição Nordeste</p>
              <p>Cabo de Santo Agostinho/PE</p>
            </div>
            <div className={styles.region}>
              <p className={styles['region-title']}>Centro de distribuição Sul/Sudeste</p>
              <p>Itajaí/SC</p>
            </div>

          </div>

        </div>

        
      </div>

    </section>
    

    
  );
};