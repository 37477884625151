import { SocialMedia } from '../index';
import { SocialMediaType } from '../socialMedia';
import styles from './SocialMediaGroup.module.scss';

interface GroupProps {
  hideable?: SocialMediaType[];
}
export default function SocialMediaGroup(props: GroupProps) {
  return (
    <div className={styles['sl-c-social-media-group']}>
      <SocialMedia classname={props.hideable && props.hideable.includes('instagram') ? 'd-none d-lg-flex' : undefined} type='instagram' />
      <SocialMedia classname={props.hideable && props.hideable.includes('whatsapp') ? 'd-none d-lg-flex' : undefined} type='whatsapp' />
      <SocialMedia classname={props.hideable && props.hideable.includes('youtube') ? 'd-none d-lg-flex' : undefined} type='youtube' />
    </div>
  );
}