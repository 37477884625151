import styles from  './Banner.module.scss';
import classnames from 'classnames';
import bannerdesk1 from '../../assets/images/Banners/banner_1desktop.webp';
import bannerdesk2 from '../../assets/images/Banners/banner_2_desktop.webp';
import bannerdesk3 from '../../assets/images/Banners/banner_3_desktop.webp';
import bannerdesk4 from '../../assets/images/Banners/banner_4_desktop.webp';

import bannermob1 from '../../assets/images/Banners/banner_1mobile.webp';
import bannermob2 from '../../assets/images/Banners/banner_2_mobile.webp';
import bannermob3 from '../../assets/images/Banners/banner_3_mobile.webp';
import bannermob4 from '../../assets/images/Banners/banner_4_mobile.webp';

import { useMediaQuery } from 'react-responsive';

import { useState } from 'react';
import Slider from 'react-slick';

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
 
};

const settingsres = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 320,
      settings: {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
  ]
};

export function Index() {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showDesktopCarousel, setShowDesktopCarousel] = useState(true);

  return (
    <section className={classnames(
      styles.spacer,
      styles['section-box'],
    )}>
      {isDesktop && showDesktopCarousel && (
        <Slider {...settings} className={styles['join-sections']}>
          <div className={styles.image}>
            <div className="slider-image">
              <img className="w-100" src={bannerdesk1} alt="Banner 1" />
            </div>
          </div>
          <div className={styles.image}>
            <div className="slider-image">
              <img className="w-100" src={bannerdesk2} alt="Banner 2" />
            </div>
          </div>
          <div className={styles.image}>
            <div className="slider-image">
              <img className="w-100" src={bannerdesk3} alt="Banner 3" />
            </div>
          </div>
          <div className={styles.image}>
            <div className="slider-image">
              <img className="w-100" src={bannerdesk4} alt="Banner 4" />
            </div>
          </div>
        </Slider>
      )}

      {!isDesktop && (
        <Slider {...settingsres}  className={styles['join-sections']}>
          <div className={styles.image}>
            <div className="slider-image">
              <img className="w-100" src={bannermob1} alt="Banner mob1" />
            </div>
          </div>
          <div className={styles.image}>
            <div className="slider-image">
              <img className="w-100" src={bannermob2} alt="Banner mob2" />
            </div>
          </div>
          <div className={styles.image}>
            <div className="slider-image">
              <img className="w-100" src={bannermob3} alt="Banner mob3" />
            </div>
          </div>
          <div className={styles.image}>
            <div className="slider-image">
              <img className="w-100" src={bannermob4} alt="Banner mob4" />
            </div>
          </div>
        </Slider>
      )}
    </section>
  );
}