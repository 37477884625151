import Router from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/Global.module.scss';
import { connect } from 'react-redux';
import { hidePopup, showPopup } from './store/actions/popupActions';
import { Alert } from './components';

const mapStateToProps = (state: any) => ({
  ...state,
});

const mapDispatchToProps = (dispatch: any) => ({
  showPopup: () => dispatch(showPopup),
  hidePopup: () => dispatch(hidePopup),
});


function App() {
  return (
    <>
      <Router />
      <Alert />
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
