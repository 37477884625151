import styles from './Testimonials.module.scss';
import classnames from 'classnames';
import depoimentos from '../../assets/images/depoimentos.svg';
import ellipse from '../../assets/images/Ellipse 4.svg';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useState } from 'react';
import { content } from './testemonialsData';

const settings = {
  dots: true,
  dotsClass: `slick-dots ${styles['custom-dots']}`,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 800,
      settings: {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
  ]
};
  
  
export default function Testimonials() {
  const [testemonialList, setTestemonialList] = useState(content);

  return (
    <div id='briefs' className={classnames([styles.spacer, 
      styles['sl-c-testimonials']])}>
  
      <div className={styles.container}>
        <div className={styles.image}>
          <img src={depoimentos} alt="depoimentos" width="90%" className={styles.img} />
        </div>
        <div className={styles.text} style={{ textAlign: 'left' }} >
          <p className={styles['orange-text']}>DEPOIMENTOS</p>
          <Slider {...settings}>
            {
              testemonialList.map((item, idx) => {
                return (
                  <div key={idx}> 
                    <div className={styles.display}>
                      <div className={styles.textContainer}>
                        <h2>{item.name}</h2>
                        <p>{item.legend}</p>
                      </div>
                    </div>
                    
                    <div className={styles.description}>
                      <p>“{item.message}”</p>
                    </div>
                  </div>
                );
              })
            }
          </Slider>
        </div>
      </div>
    </div>
  );
}
