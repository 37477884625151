import styles from './Highlights.module.scss';
import classnames from 'classnames';

import logoAMSO from '../../assets/images/maker/AMSOSolar.webp';
import logoDAH from '../../assets/images/maker/DAHSolar.png';
import logoDEYE from '../../assets/images/maker/Deye.png';
import logoSUNOVA from '../../assets/images/maker/SUNOVA.svg';

import logoRESUN from '../../assets/images/maker/RESUN.jpeg';

import wavebaixo from '../../assets/images/wave/bottomWave.svg';

import Slider from 'react-slick';
import ApiService from '../../services';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React, { useEffect, useState } from 'react';

export interface ProductItem {
  id: number;
  name: string;
  photo_url: string;
  supplier: Supplier[];
  description: string;
}

export interface Supplier {
  id: number;
  name: string;
  photo: string;
}

const settings = {
  dotsClass: `slick-dots ${styles['custom-dots']}`,
  dots: true,
  infinite: false,
  vertical: false,
  speed: 500,
  slidesToShow: 5,
  arrows: false,
  
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        arrows: false,
        slidesToShow: 5,
        slidesToScroll: 5,
        infinite: false,
        dots: true,
        dotsClass: `slick-dots ${styles['custom-dots']}`,
      },
    },
    {
      breakpoint: 600,
      settings: {
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        dots: true,
      },
    },
  ],
};
const altMakers = ['deye','dah', 'sunova','Amso', 'resun'];
const imagesMakers = [logoDEYE, logoDAH, logoSUNOVA, logoAMSO, logoRESUN];

const apiService = new ApiService();

const productsSettings = {
  dots: true,
  dotsClass: `slick-dots ${styles['custom-dots-products']}`,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        dots: false,
        arrows: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        dots: true,
        arrows: false,
        centerMode: false,
      },
    },
  ],
};

export const Index = () => {  
  const [products, setProducts] = useState<ProductItem[]>([]);

  useEffect(() => {
    apiService.loadProducts().then((response) => {
      if(response){
        setProducts(response.data);
      }
    });
  }, []);

  return (
    <section className={
      classnames(
        styles['sl-c-highligths'],
      )}>
      <div>
        <div className={classnames(styles.spaced)}>
          <Slider {...settings} className={styles['text-center']}>
            { imagesMakers.map( (maker,index) =>(
              <div  key={maker}  className={styles.image}>               
                <img src={maker} alt={altMakers[index]} />         
              </div>
            ))}

          </Slider>
       
        </div>
        <div className={styles.bottomWave}>
          <img src={wavebaixo} alt="baixo" className={classnames(styles.wavebar)} />
        </div>
        <div id='products' className={classnames(styles['highlight-bar'], styles.spaced)}>
          <div>
            <p className={styles['title-highlights']}> Produtos em destaque </p>
          </div>
          
          <div className={styles['product-card-container']}>
            <Slider {...productsSettings}>
              { products.map( (productItem) => productItem &&(
                <div key={productItem.description }>
                  <div className={styles['product-card']}> 
                    <div className={styles['container-general']}>
                      <div  className={styles['first-container']}>
                        <div className={styles['logo-card']} >
                          { productItem.supplier.map( (logo) => logo && (
                            <div key={logo.photo} >
                              <img src={logo.photo} />
                            </div>
                          ))}
                        </div>
 
                        <div className={styles.azul}>
                          <h4>{productItem.name}</h4>
                        </div>

                        <div className={styles.verde}>
                          <h4>{productItem.description}</h4>
                        </div>
                      </div>
                      <div className={styles['product-card-image']} >
                        <img src={productItem.photo_url} /> 
                        <a href="http://wa.me/+5581982281022" rel="noreferrer" target="_blank" className='btn btn-warning'> Consultar Preço </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>  

        </div>
      </div>
    </section>
  );
};