import logo from '../../assets/images/logo.png';
import logoText from '../../assets/images/logo-text.png';
import logoVertical from '../../assets/images/logo-vertical.png';
import styles from './Logo.module.scss';
import classnames from 'classnames';

type LogoAxis = 'horizontal' | 'vertical';

interface LogoProps {
  axis?: LogoAxis;
}

export default function Logo(props: LogoProps) {

  if (props.axis === 'vertical') {
    return <img src={logoVertical} style={{width: '100%'}} />;
  }

  return (
    <div aria-label='logo' className={
      classnames(
        styles['sl-c-logo'],
      )
    }>
      <img src={logo} />
      <img src={logoText} />
    </div>
  );
}