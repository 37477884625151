import { useNavigate } from 'react-router-dom';
import { Paths } from '../../routes';

export function Index() {
  const navigate = useNavigate();

  return (
    <>
      <h1>Not found</h1>
      <button onClick={() => navigate(Paths.home)}>Voltar</button>
    </>
  );
}