import { Button } from 'react-bootstrap';
import classnames from 'classnames';
import styles from './Button.module.scss';

type ButtonColor = 'none' | 'green' | 'orange';

interface ButtonProps {
    color?: ButtonColor;
    children: any;
    className?: string;
    type?: 'submit' | 'button' | undefined;
}

export default function AppButton(props: ButtonProps) {
  return (
    <Button type={props.type} className={
      classnames(
        styles['sl-c-button'],
        styles[props?.color ?? 'none'],
        props.className
      )
    }> 
      { props.children }
    </Button>
  );
}