import { Index as Home } from './home';
import { Index as AboutUs } from './aboutUs';
import { Index as Faq } from './faq';
import { Index as NotFound} from './notFound';
import { Index as Products} from './products';
import { Index as Highlights} from './highlights';


export default {
  Home,
  AboutUs,
  Faq,
  NotFound,
  Products,
  Highlights,
};