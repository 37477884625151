import { Form, Spinner } from 'react-bootstrap';
import styles from './Contact.module.scss';
import classnames from 'classnames';
import wave from '../../assets/images/contact-wave.png';
import * as C from '../';
import { useMemo, useState } from 'react';
import { ContactType } from '../../shared/types';
import ApiService, { ContactRequestBody } from '../../services';
import { useDispatch } from 'react-redux';
import { hidePopup, showPopup } from '../../store/actions/popupActions';

export default function Contact() {
  const dispatch = useDispatch();

  const [contactType, setContactType] = useState<ContactType>('other');
  const [loading, setLoading] = useState<boolean | undefined>();

  const api = useMemo(() => new ApiService(), []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const body: ContactRequestBody = {
      name: event.target[0].value,
      phone: event.target[1].value,
      email: event.target[2].value,
      // eslint-disable-next-line camelcase
      contact_type: contactType,
    };

    dispatch(showPopup);

    setLoading(true);

    setTimeout(() => {
      dispatch(hidePopup);
      setLoading(false);
      event.target[0].value = '';
      event.target[1].value = '';
      event.target[2].value = '';
    }, 2000);

    api.sendEmail(body); // [Vini, 10th april] there's no need for this to be an async function.
  };

  return (
    <div id='contact' className={classnames(
      styles['sl-c-contact'],
    )}>
      <img src={wave} className={styles.wave} />
      <div className={classnames(styles.content,styles.spacer)}>
        <p className={classnames(styles.title,styles.white, 'mb-5')}>Fale conosco</p>
        <Form className={styles.form} onSubmit={async (e) => await handleSubmit(e)}>
          <Form.Group className={classnames('mb-3', styles['form-item'])} controlId="name" >
            <Form.Control required type="text" placeholder="Nome *" />
          </Form.Group>
          <Form.Group className={classnames('mb-3', styles['form-item'])} controlId="phone">
            <Form.Control type="text" placeholder="Telefone *" />
          </Form.Group>
          <Form.Group className={classnames('mb-3', styles['form-item'])} controlId="email">
            <Form.Control required type="email" placeholder="Email *" />
          </Form.Group>
          <Form.Select 
            value={contactType} 
            onChange={(e) => setContactType(e.target.value as ContactType)} 
            className={classnames('mb-3', styles['form-item'])} 
            aria-label=""
          >
            <option value={'other'}>Você é...</option>
            <option value={'integrator'}>Integrador</option>
            <option value={'manufacturer'}>Fabricante de Equipamentos Fotovoltaicos</option>
            <option value={'other'}>Outro</option>
          </Form.Select>
          {
            loading === false 
              ? <></>
              : <C.Button color={'orange'} className={'mt-3'} type="submit"> 
                {
                  loading === undefined
                    ? <span>Enviar</span> 
                    : <Spinner />
                }
              </C.Button>
          }
        </Form>
      </div>
    </div>
  );
}