import classnames from 'classnames';
import styles from './Footer.module.scss';
import { ReactComponent as Wave } from '../../assets/images/wave.svg';
import { Logo, SocialMediaGroup } from '../index';
import { ReactComponent as WhatsappIcon } from '../../assets/icons/whatsapp.svg';
import mail from '../../assets/icons/mail.png';
import home from '../../assets/icons/home.png';

const CopyRights = () => {
  return (
    <div className={styles['sl-c-copyrights']}>
      © Orgulhosamente desenvolvido pela <a style={{textDecoration: 'none', color: 'white', cursor: 'pointer'}} href='https://www.instagram.com/witexperience/' target='_blank' rel='noreferrer'><span className='bold'>Wit Experience</span></a>
    </div>
  );
};

export default function Footer() {
  return (
    <div style={{position: 'relative', zIndex: '4'}}>
      <Wave className={styles.wave} />
      <footer className={classnames(
        styles['sl-c-footer'],
        styles.spacer,
        'd-flex flex-column flex-lg-row justify-content-between align-items-center'
      )}>
        <div className={styles.logo}>
          <Logo axis='vertical' />
        </div>
        <div className={styles.content}>
          <div className={styles.info}>
            <img src={home} className={styles.icon} />
            <span className={styles.text}>Rua Projetada D, 62 - Garapu, Cabo de Santo Agostinho/PE</span>
          </div>
          <div className={styles.info}>
            <WhatsappIcon className={styles.icon} />
            <span className={styles.text}> (81) 3524-1290 | (81) 9 8228-1022 | (81) 9 9619-4142</span>
          </div>
          <div className={styles.info}>
            <img src={mail} className={styles.icon} />
            <span className={styles.text}>contato@solarlifenergy.com.br</span>
          </div>
          <div className={classnames(
            'mt-4',
            styles['social-media']
          )}>
            <SocialMediaGroup />
          </div>
        </div>
      </footer>
      <CopyRights />
    </div>
  );
}