const showPopup = {
  type: 'popup',
  payload: true,
};

const hidePopup = {
  type: 'popup',
  payload: false,
};

export { showPopup, hidePopup };