import classnames from 'classnames';
import styles from './SocialMedia.module.scss';
import { ReactComponent as WhatsappIcon } from '../../assets/icons/whatsapp.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/icons/youtube.svg';
import InstagramIcon from '../../assets/icons/instagram.png';

export type SocialMediaType = 'whatsapp' | 'instagram' | 'youtube';
type SocialMediaSize = 'large' | 'small';
type SocialMediaColor = 'orange' | 'green';

interface SocialMediaProps {
    type: SocialMediaType;
    size?: SocialMediaSize;
    color?: SocialMediaColor;
    classname?: string;
}

export default function SocialMedia(props: SocialMediaProps) {
  const Icon = () => {
    switch (props.type) {
    case 'whatsapp':
      return <a href="http://wa.me/5581982281022" target='_blank' rel="noreferrer">  <WhatsappIcon /> </a> ;
    case 'instagram':
      return <a href="https://www.instagram.com/solarlifeenergy/" target='_blank' rel="noreferrer"> <img src={InstagramIcon} /> </a>;
    case 'youtube':
      return <a href="https://www.youtube.com/@solarlifeenergy1622" target='_blank' rel="noreferrer"> <YoutubeIcon /> </a>;
    default:
      return <></>;    
    }
  };

  if (!props.type) return <></>;

  return (
    <div className={
      classnames(
        styles['sl-c-social-media'],
        props.color ?? styles['orange'],
        props.size ?? styles['small'],
        props.classname,
      )}>
      <Icon />
    </div>
  );
}