import styles from './Advantage.module.scss';
import classnames from 'classnames';


interface AdvantageContent {
  text: string;
  detach?: boolean;
}

interface AdvantageIcons {
  main: string;
  leftIcon: string;
  rightIcon: string;
}
  
export interface AdvantageItem {
  images: AdvantageIcons;
  alt?: string;
  title: string;
  content: AdvantageContent[];
  extraComponent?: any;
}


interface AdvantageProps {
  type: 'white' | 'blue';
  item: AdvantageItem;
}

export default function Advantage(props: AdvantageProps) {
  return (
    <div className={
      classnames([
        styles.spacer, 
        styles['sl-c-advantage'], 
        props.type === 'blue' ? styles.blue : styles.white])
    }>
      <div className={styles['image-container']}>
        <img src={props.item.images.main} alt={props.item.alt} className={styles.image} />
        <img src={props.item.images.leftIcon} alt={'Ícone.'} className={styles['left-img']} />
        <img src={props.item.images.rightIcon} alt={'Ícone.'} className={styles['right-img']} />
      </div>
      <div className={styles.content}>
        <p className={styles.title}>{props.item.title}</p>
        <p className={styles.text}>
          {  
            props.item.content.map((c) => {
              return c.detach
                ? <span className={styles.detach}>{c.text}</span>
                : <>{c.text}</>;
            })
          }
        </p>
        { props.item.extraComponent ?? <></> }
      </div>
    </div>
  );
}