import React from 'react';
import styles from './Navbar.module.scss';
import { Paths } from '../../routes';
import { Link } from 'react-router-dom';
import { Button, Logo, SocialMediaGroup } from '../index';
import { Container, Nav, Navbar, } from 'react-bootstrap';
import classnames from 'classnames';
import { ReactComponent as KeyIcon } from '../../assets/icons/key.svg';
import { ReactComponent as ClientIcon } from '../../assets/icons/client.svg';
import { HashLink } from 'react-router-hash-link';
import { HomeSection } from '../../shared/types';

interface NavLinkProps {
  text: string;
  path: string;
  section?: HomeSection;
}

const TopContact = ()  => {
  return (
    <div className={classnames({
      [styles.spacer]: true,
      [styles['sl-c-top-contact']]: true,
    })}>
      <div className='d-flex flex-row align-items-center'>
        <SocialMediaGroup hideable={['instagram', 'youtube']} />
        <p className={styles.text}> (81) 3524-1290 | (81) 9 8228-1022 | (81) 9 9619-4142</p>
      </div>

      <div className={styles.buttons}>
        <a  className={styles.transparent} href="http://wa.me/5581982281022" target="_blank" rel="noreferrer">
          <Button>
            <ClientIcon /> 
            <p className={styles['be-integrator-margin']}>SEJA UM INTEGRADOR</p> 
          </Button>
        </a>
        <a className={styles.transparent} href="http://scev2.solarlifenergy.com.br/login" target="_blank" rel="noreferrer">
          <Button color='green'>
            <KeyIcon />
            <p>ÁREA DO INTEGRADOR</p>
          </Button>
        </a>
      </div>
    </div>
  );
};

const AppNavbar = () => {
  const paths: NavLinkProps[] = [
    { text: 'Sobre', path: Paths.aboutUs },
    { text: 'Produtos', path: Paths.home, section: 'products' },
    // { text: 'Fabricantes', path: Paths.home, section: 'manufacturers' },
    { text: 'Vantagens', path: Paths.home, section: 'advantages' },
    { text: 'Depoimentos', path: Paths.home, section: 'briefs' },
    { text: 'Fale conosco', path: Paths.home, section: 'contact' },
  ];

  return (
    <div className={classnames(
      styles['sl-c-navbar'],
      styles['spacer'],
      'd-lg-flex flex-row '
    )}>
      <div className={styles['navbar-container']}>
        <div className={styles.brand}>
          <Link to={Paths.home}>
            <Navbar.Brand className={styles['navbar-remove-style']}>
              <Logo />
            </Navbar.Brand>
          </Link>
        </div>

        <div className={styles['navbar-toogle']}>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        </div>
      </div>

      <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
        <Nav>
          { paths.map((link: NavLinkProps) => (
            <Nav.Link key={link.path}>
              <HashLink smooth to={`${link.path}#${link.section ?? ''}`} className={styles.link}>{ link.text }</HashLink>
            </Nav.Link>
          )) }
        </Nav>
      </Navbar.Collapse>
    </div>
  );
};

export default function Index() {
  return (
    <Navbar  collapseOnSelect expand="lg" bg='white' className='p-0 w-100'>
      <Container className={classnames(['flex-column p-0 m-0 ', styles['max-width-total'] ])}>
        <TopContact />
        <AppNavbar />
      </Container>
    </Navbar>
  );
}
