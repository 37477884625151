import styles from  './Products.module.scss';

import Modulos from '../../assets/images/product/modulos.png';
import Inversores from '../../assets/images/product/inversores.png';
import Kits from '../../assets/images/product/kits.png';
import Cabos from '../../assets/images/product/cabos.png';
import StringBox from '../../assets/images/product/stringBox.png';
import Fixacoes from '../../assets/images/product/fixacoes.png';
import Conectores from '../../assets/images/product/conectores.png';
import Estruturas from '../../assets/images/product/estrutura.png';

import iconModulos from '../../assets/images/product/icons/iconModulos.svg';
import iconInversores from '../../assets/images/product/icons/iconInversor.svg';
import iconKits from '../../assets/images/product/icons/iconKits.svg'; 
import iconCabos from '../../assets/images/product/icons/iconCabo.svg';
import iconStrings from '../../assets/images/product/icons/iconStringBox.svg';
import iconFixacoes from '../../assets/images/product/icons/iconFixacoes.svg'; 
import iconConector from '../../assets/images/product/icons/iconConector.svg';
import iconEstrutura from '../../assets/images/product/icons/iconEstrutura.svg'; 

import { ReactComponent as WaveProduct } from '../../assets/images/product-wave.svg';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const settings = {
  dots: true,
  dotsClass: `slick-dots ${styles['custom-dots']}`,
  infinite: false,
  speed: 500,
  slidesToShow: 8,
  slidesToScroll: 8,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },
    {
      breakpoint: 800,
      settings: {
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
  ]
};

export function Index() {
  const products = ['Módulos', 'Inversores', 'Kits', 'Cabos', 'String Box', 'Fixações', 'Conectores', 'Estruturas'];
  const images = [Modulos, Inversores, Kits, Cabos, StringBox, Fixacoes, Conectores, Estruturas];
  const icons = [iconModulos,iconInversores,iconKits,iconCabos,iconStrings,iconFixacoes,iconConector,iconEstrutura];

  return (
    <section className={styles['section-box']}>
      <div className={styles.content}>
        <Slider {...settings}>
          { products.map( (product,index) =>(
            <div  key={product} className={styles.card}>            
              <div className={styles.title}>    
                <p>
                  {product} 
                </p>      
              </div>
              <div className={styles['card-icon-container']}>
                <div className={styles['card-icon']}>
                  <img src={icons[index]} alt="" />     
                </div>
              </div>
              <div className={styles.image}>       
                <img src={images[index]} alt="" />         
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <WaveProduct className={styles.wave} />
    </section>
    
  );
}