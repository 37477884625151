import customerServiceImage from '../../assets/images/attendance/atendimento-rapido-e-humanizado.png';
import customerLeftIcon from '../../assets/images/attendance/Icon.png';
import customerRightIcon from '../../assets/images/attendance/Icon (1).png';

import paymentImage from '../../assets/images/payment/pagamento-facilitado.png';
import paymentLeftIcon from '../../assets/images/payment/Icon.png';
import paymentRightIcon from '../../assets/images/payment/Icon (1).png';

import financing from '../../assets/images/finance/financiamento.png';
import financingLeftIcon from '../../assets/images/finance/Icon.png';
import financingRightIcon from '../../assets/images/finance/Icon (1).png';


import club from '../../assets/images/advantageClub/clube-de-vantagens.png';
import clubLeftIcon from '../../assets/images/advantageClub/Icon.png';
import clubRightIcon from '../../assets/images/advantageClub/Icon (1).png';

import freight from '../../assets/images/freight/frete_entrega.png';
import freightLeftIcon from '../../assets/images/freight/Icon.png';
import freightRightIcon from '../../assets/images/freight/Icon (1).png';


import forkLift from '../../assets/images/forklift/empilhadeira-solar.png';
import forkLiftLeftIcon from '../../assets/images/forklift/Icon.png';
import forkLiftRightIcon from '../../assets/images/forklift/Icon (1).png';

import engineering from '../../assets/images/engineering/departamento-de-engenharia.png';
import engineeringLeftIcon from '../../assets/images/engineering/Icon.png';
import engineeringRightIcon from '../../assets/images/engineering/Icon (1).png';

import homolog from '../../assets/images/homolog/homologacao.png';
import homologLeftIcon from '../../assets/images/homolog/Icon.png';
import homologRightIcon from '../../assets/images/homolog/Icon (1).png';

import * as BankImages from '../../assets/images/banks/index';
import { AdvantageItem } from '../advantage';
import styles from './AdvantagesSection.module.scss';

const content: AdvantageItem[] = [
  {
    images: {
      main: customerServiceImage,
      leftIcon: customerLeftIcon,
      rightIcon: customerRightIcon,
    },
    alt: 'Imagem de um boneco com fone de ouvido em frente à um notebook.',
    title: 'Atendimento rápido e humanizado',
    content: [
      {text: 'Somos amigos do integrador, garantimos um pós-venda presente e '},
      {text: 'oferecemos o suporte', detach: true},
      {text: ' necessário para suas instalações.'}
    ]
  }, 
  {
    images: {
      main: paymentImage,
      leftIcon: paymentLeftIcon,
      rightIcon: paymentRightIcon,
    },
    alt: 'Ilustração de uma máquina de cartão de crédito.',
    title: 'Pagamento facilitado',
    content: [
      {text: 'Parcele suas compras '},
      {text: 'em até 36x ', detach: true},
      {text: 'e em mais de um cartão.'}
    ]
  },
  {
    images: {
      main: financing,
      leftIcon: financingLeftIcon,
      rightIcon: financingRightIcon,
    },
    alt: 'Ilustração de uma saco de dinheiro e moedas ao lado.',
    title: 'Financiamento',
    content: [
      {text: 'Financie ', detach: true},
      {text: 'seu kit diretamente conosco.'}
    ],
    extraComponent: (
      <div className={styles['sl-c-bank-images']}>
        <img className={styles['bv-height']} src={BankImages.bv} alt="Logo do banco BV" style={{marginRight: '1rem'}} />
        <img src={BankImages.bancoBrasil} alt="Logo do Banco do Brasil" style={{marginRight: '1rem'}} />
        <img src={BankImages.losango} alt="Logo do banco Losango" style={{marginRight: '1rem'}} />
        <img src={BankImages.santander} alt="Logo do banco Santander" style={{marginRight: '1rem'}} />
      </div>
    )
  },
  {
    images: {
      main: club,
      leftIcon: clubLeftIcon,
      rightIcon: clubRightIcon,
    },
    alt: 'Ilustração com um Drone e um Painel Solar.',
    title: 'Clube de Vantagens',
    content: [
      {text: 'Com '},
      {text: 'prêmios incríveis ', detach: true},
      {text: 'para você ou sua empresa, como drones, kits fotovoltaicos, material de papelaria, plotagens e fardamentos para o seu negócio.'}
    ]
  },
  {
    images: {
      main: freight,
      leftIcon: freightLeftIcon,
      rightIcon: freightRightIcon,
    },
    alt: 'Ilustração de um caminhão de entregas.',
    title: 'Frete Grátis',
    content: [
      {text: 'Frete Grátis para '},
      {text: 'Recife e Região Metropolitana ', detach: true},
      {text: 'em até '},
      {text: '72h úteis', detach: true},
      {text: '. Deixamos os equipamentos diretamente na sua obra!'}
    ]
  },
  {
    images: {
      main: forkLift,
      leftIcon: forkLiftLeftIcon,
      rightIcon: forkLiftRightIcon,
    },
    alt: 'Ilustração de uma empilhadeira.',
    title: 'Amplo estoque',
    content: [
      {text: 'Possuímos '},
      {text: 'o maior estoque ', detach: true},
      {text: 'de equipamentos fotovoltaicos do Nordeste e contamos com Centros de Distribuição em Pernambuco e Santa Catarina.'}
    ]
  },
  {
    images: {
      main: engineering,
      leftIcon: engineeringLeftIcon,
      rightIcon: engineeringRightIcon,
    },
    alt: 'Ilustração de duas mãos com várias ferramentas.',
    title: 'Departamento de Engenharia',
    content: [
      {text: 'Contamos com uma equipe técnica de '},
      {text: 'especialistas ', detach: true},
      {text: 'para tirar suas dúvidas em tempo real sobre os equipamentos e '},
      {text: 'otimizar ', detach: true},
      {text: 'o seu projeto ao máximo.'},
    ]
  },
  {
    images: {
      main: homolog,
      leftIcon: homologLeftIcon,
      rightIcon: homologRightIcon,
    },
    alt: 'Ilustração de dois documentos com uma lupa.',
    title: 'Homologação grátis',
    content: [
      {text: 'Garanta a '},
      {text: 'homologação ', detach: true},
      {text: 'do seu projeto na compra de '},
      {text: 'kits fechados', detach: true},
      {text: '.'},
    ]
  },
];

export {content};