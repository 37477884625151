import styles from './Meet.module.scss';
import classnames from 'classnames';

export default function MeetAdvantage() {
  return (
    <div className={
      classnames([
        styles.spacer, 
        styles['sl-c-meet-advantage'], 
      ])
    }>
      <div id='advantages' className={styles.espa}>
        <p className={styles.white}> Conheça as vantagens de ser um </p>
        <p className={styles.orange}> INTEGRADOR SOLAR LIFE ENERGY </p>
      </div>
    </div>
  );
}