import axios, { AxiosHeaders } from 'axios';
import { ContactType } from '../shared/types';

interface IApiService {
  sendEmail(body: ContactRequestBody): Promise<void>;
  loadProducts(): Promise<void>;
}

export interface ContactRequestBody {
  name: string;
  email: string;
  phone: string;
  contact_type: ContactType;
}

class ApiService implements IApiService {
  private baseUrl: string;
  private headers: any;
  private http;

  constructor() {
    this.baseUrl = 'https://viniak.pythonanywhere.com/';
    this.headers = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST,GET',
      'Access-Control-Allow-Headers': '*',
      'Content-Type': 'application/json',
    };
    this.http = axios.create({ baseURL: this.baseUrl, headers: this.headers, });
  }

  public async sendEmail(body: ContactRequestBody) {
    try {
      const response = await this.http.post('/contact/', body, { headers: this.headers, } );
    } catch (error) {
      return;
    }
  }

  public async loadProducts(): Promise<any> {
    try {
      const response = await this.http.get('/products/', { headers: this.headers, } );
      return response;
    } catch (error) {
      return;
    }
  }
}

export default ApiService;